import React, { useEffect, useState, useMemo, useRef } from 'react';
import styled, { css } from 'styled-components';

import { MoreMenu, IDropdownOption, isSynastry, isPrognostics, isRelocation, CircleMode, EditFormType, IPlace, IFormData, IWidgetsItem } from 'src/libs';

import store from 'src/store';
import { clickOutsideHook, switcherHook, useKeyHook } from 'src/hooks';
import { checkGenChanged, clearURLSearchParams, getDeviceDetect } from 'src/utils';
import dispatcher from 'src/dispatcher';
import { nowLocalISOString } from '../../../utils';
import { compatibilityToPartner, getModeData, getModeMetaData, isCompatibility, isPartner } from '../utils';

import { ChevronsUpDownIcon } from 'src/assets/icons/system';
import { ISelectedItem } from 'src/types';
import TimeControl, { switcherMode, TimeControlType } from './TimeControl';
import { TimeLineMode } from './TimeControl/TimeLine';
import Attw from 'src/components/AdvancedTooltipWrapper';
import EditForm from 'src/components/EditForm';
import PopupNotify from 'src/ui/PopupNotify';
import Select from 'src/ui/Select';
import InstrumentButton from 'src/components/InstrumentButton';
import { useTranslation } from 'src/i18n/useTranslation';
import { ChevronUpDownIcon } from 'src/assets/icons/system/24';
import WidgetsButtons from '../WidgetsButtons';
import Confirmation from "../../../components/Confirmation";
import api from 'src/api';


import {
  NatalIcon,
  SynastryIcon,
  PrognosticsIcon,
  HorarIcon,
  SoulIcon,
  CompatibilityIcon,
  RelocationIcon
} from 'src/assets/icons/maps';

import { RefreshIcon, ShevronDownIcon } from 'src/assets/icons/system';
import { observer } from 'mobx-react';
import { EInitSynastryTransitsCommand } from 'src/store/InstrumentsStore';

const relocationIcon = <svg><RelocationIcon /></svg>

const majorModes = ['natal', 'synastry', 'prognostics', 'horar', 'soul', 'relocation']; //

const { isTablet, isIPad } = getDeviceDetect();

const screenWidth = screen.width || window.innerWidth;

function getModeIndex(showEdit: string | null, modes: CircleMode[]) {
  if (showEdit) {
    const idx = majorModes.indexOf(showEdit);
    if (idx !== -1) {
      return idx;
    }
  }

  if (isSynastry(modes)) return 1;
  if (isPrognostics(modes)) return 2;
  if (modes.includes('horar')) return 3;
  if (modes.includes('soul')) return 4;
  if (isRelocation(modes)) return 5;


  return 0;
}

interface IProps {
  modes: CircleMode[];
  form: IFormData;
  currentMode: CircleMode;
  widgets: IWidgetsItem[];
  auxModes: CircleMode[];
  autoPrediction: boolean;
  showEdit: string | null;
  limitedAccess: boolean;
  limitedAccessAction: () => void;
  onModeChanged(mode: CircleMode | CircleMode[]): void;
  onFormEdit(form: IFormData): void;
  showWidget(name: string): void;
  showAllWidgets(): void;
  onDateTimeChanged(dt: string, forMode: CircleMode, forPlace: IPlace, clearGen?: boolean): void;
  onReplace(from: string, to: string): void;
  onHold?(id: string, v: boolean): void;
  setShowEdit(val: string | null): void;
}

const menuModes: (ISelectedItem & {
  mode: string;
})[] = [
    {
      id: 0,
      Icon: NatalIcon,
      title: "astro.natal",
      mode: 'natal'
    },
    {
      id: 1,
      Icon: SynastryIcon,
      title: "astro.synastry",
      mode: 'synastry'
    },
    {
      id: 2,
      Icon: PrognosticsIcon,
      title: "astro.prognostics",
      mode: 'prognostics'
    },
    {
      id: 3,
      Icon: HorarIcon,
      title: "astro.horar",
      mode: 'horar'
    },
    {
      id: 4,
      Icon: SoulIcon,
      title: "astro.formulaSoul",
      mode: 'soul'
    },
    {
      // RU
      id: 5,
      Icon: RelocationIcon,
      title: "chronos.app.settings.relocation",
      mode: 'relocation'
    },
  ];

const getIconByName = (name: string) => {
  const targetMode = menuModes.find(m => m.mode === name);
  return targetMode?.Icon;
}

export default observer(function Footer(props: IProps) {
  const { showEdit, setShowEdit } = props;

  const [showTimeLineBase, setShowTimeLineBase] = useState<TimeLineMode>(null);
  const [showTimeLineExt, setShowTimeLineExt] = useState<TimeLineMode>(null);
  const [showSecondTimeControl, setShowSecondTimeControl] = useState<boolean>(false);
  const [selectedModeNumber, setSelectedModeNumber] = useState<number>(0);

  const { t } = useTranslation();
  const leftGroupRef = useRef<HTMLDivElement>(null);
  const centerGroupRef = useRef<HTMLDivElement>(null);

  const showIsLockedPopup = switcherHook(false);
  const showIsLockedLeftPopup = switcherHook(false);
  const showConfirmation = switcherHook(false);
  const showRefreshMap = !isCompatibility(props.currentMode)

  const isLocked = !props.form.access.isPersonal;

  const compatibilityAuxModes = useMemo(() => props.auxModes.filter(isCompatibility), [props.auxModes]);
  const noCompatibilityAuxModes = useMemo(() => {
    if (props.form?.cosmogram && props.auxModes.includes('transits')) {
      return props.auxModes.filter(m => !isCompatibility(m) && (m === 'transits'))
    } else return props.auxModes.filter(m => !isCompatibility(m))
  }
    , [props.auxModes, props.form.cosmogram]);


  const { mapIndicatorCurrent: MapIndicator } = store.instruments;
  const isAddSynPrognostics = store.instruments.mapIndicatorCurrent.initSynastryTransits === 1;

  const partnerModes = useMemo(() => props.modes.filter(isPartner), [props.modes]);
  const compatibilityModes = useMemo(() => props.modes.filter(isCompatibility), [props.modes]);

  // может быть выбран только один режим совместимости, по этому проверяем только первый режим compatibilityModes[0]
  const showSynastryTransits = props.form.syn_prognostics && compatibilityModes.length;

  const showTimeControlsSelector = (isSynastry(props.modes) && props.modes.length > 1) || showSynastryTransits;

  const modeIndex = getModeIndex(showEdit, props.modes);
  const workWidgets = props.widgets.filter(item => !['devplan', 'autoprediction', 'interpretation'].includes(item.id));
  const paidBuildings = props.widgets.filter(item => ['devplan', 'autoprediction', 'interpretation'].includes(item.id));

  const showNatalTimeControl = isPrognostics(props.currentMode)
    && props.modes.some(mode => (MapIndicator.prognosticsNatal as any)[mode])
    || (
      isSynastry(props.currentMode)
      && MapIndicator.partnerNatal
    )

  const closeEdit = (editForm?: string | null) => {
    if (['syn_prognostics'].includes(editForm!) && !props.form.syn_prognostics) {
      store.instruments.setInitSynastryTransits(EInitSynastryTransitsCommand.NONE); // отменяем инициализацию формы прогностики в синастрии
    }
    setShowEdit(null);
  };

  clickOutsideHook(leftGroupRef, () => { showIsLockedLeftPopup.off() });

  clickOutsideHook(centerGroupRef, () => { showIsLockedPopup.off() });

  useEffect(() => {
    setShowTimeLineExt(null);
    setShowTimeLineBase(null);
  }, [props.modes])

  useKeyHook([
    'KeyE',
    'KeyD'
  ], {
    onKeyDown: key => {
      if (store.settings.user.isLimitedAccess) return
      if (key === 'KeyE' && !isLocked) {
        showEdit === 'natal' ? setShowEdit(null) : setTimeout(() => { setShowEdit('natal') }, 0);
      }
      if (key === 'KeyD' && !isLocked) timeLineHandler();
    }
  }, [
    showEdit,
    showTimeLineExt,
    showTimeLineBase,
    props.modes
  ], {
    ignoreActiveInput: false,
    stopPropagation: false
  });


  const onModeChanged = (mode: CircleMode, add?: boolean) => {
    if (props.modes.length === 1 && props.modes[0] === mode) return;

    props.onModeChanged(add ? mode : [mode]);
    setShowEdit(null);
  };

  const timeLineSynastryHandler = () => {
    if (showTimeLineExt === 'second') {
      if (props.modes.some(isPartner) || props.modes.some(isCompatibility)) {
        setShowTimeLineExt(null);
        setShowTimeLineBase('day');
      } else {
        setShowTimeLineExt('day');
      }
      return;
    }
    if (showTimeLineBase === 'second') {
      if (props.modes.some(isPartner) || props.modes.some(isCompatibility)) {
        setShowTimeLineExt('day');
        setShowTimeLineBase(null);
      } else {
        setShowTimeLineBase('day');
      }
      return;
    }

    (showTimeLineExt || props.modes.some(isPartner))
      ? setShowTimeLineExt((s) => switcherMode[String(s)])
      : setShowTimeLineBase((s) => switcherMode[String(s)]);
    return;
  }

  const timeLinePrognosticsHandler = () => {
    if (showTimeLineExt === 'second') {
      setShowTimeLineExt(null);
      setShowTimeLineBase('day');
      return;
    }
    if (showTimeLineBase === 'second') {
      setShowTimeLineExt('day');
      setShowTimeLineBase(null);
      return;
    }

    showTimeLineExt
      ? setShowTimeLineExt((s) => switcherMode[String(s)])
      : setShowTimeLineBase((s) => switcherMode[String(s)]);
    return;
  }

  const timeLineHandler = () => {
    const hasExt = isSynastry(props.modes) || isPrognostics(props.modes);

    if (hasExt) {
      if (isSynastry(props.modes)) {
        timeLineSynastryHandler();
      } else if (isPrognostics(props.modes)) {
        timeLinePrognosticsHandler();
      }
      return;
    }

    setShowTimeLineBase(switcherMode[String(showTimeLineBase)]);
  };

  const getBaseTimeId = () => props.modes.includes('horar') ? 'horar' : 'natal';

  const getSecondTimeId = () =>
    isSynastry(props.modes)
      ? 'synastry'
      : 'prognostics';

  const handleRefresh = (): void => {
    const isoDate = nowLocalISOString();
    const timeData = showSecondTimeControl || (!isTablet && secondTimeData)
      ? secondTimeData
      : baseTimeData;

    props.onDateTimeChanged(isoDate, timeData.forMode, timeData.place);
  };

  const onSubmitHandler = () => {
    handleRefresh()
    showConfirmation.off()
  }

  const onRefreshHandler = () => {

    if (isLocked) {
      showIsLockedPopup.on();
      return;
    }

    if (props.limitedAccess) {
      props.limitedAccessAction?.();
      return;
    }

    const {
      gen,
      natal: { dt, place: _place }
    } = props.form

    const isoDate = nowLocalISOString();
    const hasGen = checkGenChanged(gen, isoDate, dt, props.form.natal.place, _place)
    const natalOrSoul = (el: string) => el === 'natal' || el === 'soul' || el === 'syn_natal' || /^partner\d+$/.test(el);
    const isNatalOrSoul = props.modes.some(natalOrSoul);

    isNatalOrSoul && hasGen ? showConfirmation.on() : handleRefresh()
  }

  const onDateTimeChanged = (type: TimeControlType, dt: string, clearGen: boolean) => {
    // console.log('onDateTimeChanged', dt, type)

    const timeDate = type === 'base' ? baseTimeData : secondTimeData;

    props?.onDateTimeChanged(dt, timeDate.forMode, timeDate.place, clearGen);
  }

  const modeMenu: (IDropdownOption & { mode: string; })[] = [
    {
      value: 0,
      label: "astro.natal",
      icon: <NatalIcon style={{ width: '1rem', height: '1rem' }} />,
      mode: 'natal',
      action: () => onModeChanged('natal'),
      visible: true,
      disabled: false
    },
    {
      value: 1,
      icon: <SynastryIcon style={{ width: '1rem', height: '1rem' }} />,
      label: "astro.synastry",
      mode: 'synastry',
      visible: true,
      disabled: props.limitedAccess,
      action: (item: IDropdownOption) => {
        if (isLocked) {
          showIsLockedLeftPopup.on();
          return;
        }

        if (item.disabled) {
          props.limitedAccessAction();
          return
        }

        if (props.form.partners?.length! > 0) {
          onModeChanged('partner1');
        } else {
          setShowEdit('synastry');
        };
      },
    },
    {
      value: 2,
      icon: <PrognosticsIcon style={{ width: '1rem', height: '1rem' }} />,
      label: "astro.prognostics",
      mode: 'prognostics',
      disabled: props.limitedAccess,
      action: (item: IDropdownOption) => {
        if (item.disabled) {
          props.limitedAccessAction();
          return
        }
        if (props.form.prognostics) onModeChanged('directions');
        else {
          if (isLocked) {
            showIsLockedLeftPopup.on();
            return;
          }
          setShowEdit('prognostics');
        };
      }
    },
    {
      value: 3,
      icon: <HorarIcon style={{ width: '1rem', height: '1rem' }} />,
      label: "astro.horar",
      mode: 'horar',
      disabled: props.limitedAccess,
      action: (item: IDropdownOption) => {
        if (item.disabled) {
          props.limitedAccessAction();
          return
        }
        if (props.form.horar) onModeChanged('horar');
        else {
          if (isLocked) {
            showIsLockedLeftPopup.on();
            return;
          }
          setShowEdit('horar')
        };
      }
    },
    {
      value: 4,
      icon: <SoulIcon style={{ width: '1rem', height: '1rem' }} />,
      label: "astro.formulaSoul",
      mode: 'soul',
      disabled: props.limitedAccess,
      action: (item: IDropdownOption) => {
        if (item.disabled) {
          props.limitedAccessAction();
          return
        }
        onModeChanged('soul')
      }
    },
    {
      // RU
      value: 5,
      icon: <RelocationIcon style={{ width: '1rem', height: '1rem' }} />,
      label: "chronos.app.settings.relocation",
      mode: 'relocation',
      action: (item: IDropdownOption) => {
        if (item.disabled) {
          props.limitedAccessAction()
          return
        } else {
          if (isLocked) {
            showIsLockedLeftPopup.on()
            return
          }

          if (props.form.relocation) {
            onModeChanged('relocation_natal')
          } else {
            setShowEdit('relocation')
          }
        }
      },
      disabled: props.limitedAccess
    },
  ];

  const modeOptions = useMemo(() => {
    return modeMenu.map(item => {
      const _item: any = item;
      item.label = t(item.label!)

      item.visible = !(
        item.value !== 0 && item.value !== 2 && props.form.cosmogram ||
        item.value >= 3 && item.value !== 5 && !store.settings.user.auth.permissions?.hasProAccess
      );

      item.color = (
        item.value === modeIndex
      ) ? 'var(--accent-blue)' : 'inherit'

      _item.badge = (
        item.value === 1 && !props.form.partners?.length ||
        item.value === 2 && !props.form.prognostics ||
        item.value === 3 && !props.form.horar ||
        item.value === 5 && !props.form.relocation
      ) ? <span style={{ fontSize: '1.5rem', fontWeight: 500, marginLeft: 'auto' }}>+</span> : undefined;

      return item;
    });
  }, [props.form, props.modes]);

  const modeSelectOptions = React.useMemo((): ISelectedItem[] => {
    const result: ISelectedItem[] = [];

    props.modes.forEach((mode: CircleMode, idx: number) => {
      result.push({
        id: idx,
        mode,
        Icon: getModeMetaData(isCompatibility(mode) ? compatibilityToPartner(mode) : mode)?.icon,
        title: getModeData(mode, props.form)?.name || '',
        action: () => dispatcher.emit('scrollToMode', mode),
        RightIcon: () => null
      })
      // Если есть совместимость, то добавляем в список Натал
      if (isCompatibility(mode)) {
        result.push({
          id: props.modes.length,
          mode: 'natal',
          Icon: getModeMetaData('natal')?.icon,
          title: `${t('astro.natal')}`,
          action: () => dispatcher.emit('scrollToMode', mode)
        })
      }
    })

    return result;
  }, [props.modes]);



  const [baseTimeData, secondTimeData, BaseTimeControlIcon, SecondTimeControlIcon] = useMemo(() => {

    if (props.modes.includes('horar')) {
      return [getModeData('horar', props.form), null, HorarIcon, null];
    }

    if (props.modes.includes('soul')) {
      return [getModeData('natal', props.form), null, NatalIcon, null];
    }

    if (isPrognostics(props.modes)) {
      const result = [getModeData('natal', props.form), getModeData(props.currentMode, props.form), NatalIcon, PrognosticsIcon];
      return [...result];
    }

    if (isSynastry(props.modes)) {

      if (props.modes.length >= 2 && (props.modes.length === partnerModes.length)) {

        return [
          getModeData(props.currentMode, props.form),
          getModeData('natal', props.form),
          getModeMetaData(compatibilityToPartner(props.currentMode)).icon,
          NatalIcon,
        ];

        // если выбраны и партнеры и совместимости
      } else {

        const selectedMode = modeSelectOptions.find(({ id }) => id === selectedModeNumber)?.mode as CircleMode;
        // console.log('selectedMode', selectedMode);

        // debugger

        const baseTimeData = getModeData(showSynastryTransits ? selectedMode : props.currentMode, props.form);
        const secondTimeData = getModeData(showSynastryTransits ? 'syn_prognostics' : 'natal', props.form);

        const BaseTimeControlIcon = getModeMetaData(compatibilityToPartner(showSynastryTransits ? selectedMode : props.currentMode)).icon;
        const SecondTimeControlIcon = getModeMetaData(showSynastryTransits ? 'syn_prognostics' : 'natal').icon;

        const forTimeControlData = [baseTimeData, secondTimeData];
        const icons = [BaseTimeControlIcon, SecondTimeControlIcon]

        // реверс крутилки делаем только с отключенным транзитом
        if (MapIndicator.compatibilityReversed && !showSynastryTransits) {
          forTimeControlData.reverse()
          icons.reverse()
        }

        // console.log('crutilka data - ', JSON.parse(JSON.stringify(forTimeControlData)))

        // debugger
        return [...forTimeControlData, ...icons];

      }
    }

    return [getModeData('natal', props.form), null, NatalIcon, null];

  },
    [
      props.modes,
      props.currentMode,
      MapIndicator,
      showSynastryTransits,
      props.form,
      selectedModeNumber
    ]);


  return (
    <Container noTime={isLocked && !props.form.access.showPersonalData} className="no-print">
      <LeftGroup ref={leftGroupRef}>
        {<MoreMenu
          options={modeOptions}
          button={<ModesButton up={true}><ShevronDownIcon /></ModesButton>}
          position="top-right"
          openClass="open-menu"
          detectMobile={() => false}
        />}
        <EditFormWrapper>

          <InstrumentButton
            id='natal'
            isActive={true}
            onHold={() => props.onHold?.('natal', true)}
            onHoldEnd={() => props.onHold?.('natal', false)}
            hasData={true}
            onEdit={id => setShowEdit(id === showEdit ? null : id)}
            isLocked={isLocked}
            activeStyle='color'
            icon={getIconByName('natal')}
          />

          {showEdit === 'natal' &&
            <EditForm
              type="natal"
              onClose={closeEdit}
              form={props.form}
              onSubmit={props.onFormEdit}
              currentMode={props.currentMode}
            />
          }

        </EditFormWrapper>


        {(!props.modes.includes('natal') || !!showEdit && showEdit !== 'natal') &&
          <EditFormWrapper>
            <Attw text={["astro.natal", "astro.synastry", "astro.prognostics", "astro.horar", "astro.formulaSoul", "chronos.app.settings.relocation"][modeIndex]}>
              <InstrumentButton
                id={majorModes[modeIndex]}
                isActive={true}
                hasBorder={true}
                hasData={[true, !!props.form.synastry || !!props.form.partners?.length, !!props.form.prognostics, !!props.form.horar, true, Object.keys(props.form.relocation ?? {}).length > 1][modeIndex]}
                onEdit={showEdit || !props.modes.includes('soul') ?
                  id => setShowEdit(id === showEdit ? null : id) :
                  undefined
                }
                isLocked={isLocked}
                icon={getIconByName(majorModes[modeIndex])}
              />
            </Attw>
            {(showEdit !== 'natal' && showEdit === majorModes[modeIndex]) &&
              <EditForm
                type={showEdit as EditFormType}
                onClose={closeEdit}
                form={props.form}
                onSubmit={props.onFormEdit}
                currentMode={props.currentMode}
              />
            }
          </EditFormWrapper>
        }
        {/* если заполнена синастрия, то показываем кнопку для редактирования синастрической прогностики */}
        {
          Boolean(compatibilityModes?.length)
          && (isAddSynPrognostics || Boolean(props.form.syn_prognostics)) &&

          <EditFormWrapper>
            <Attw text={"astro.prognostics"}>
              <InstrumentButton
                id={'syn_prognostics'}
                isActive={true}
                hasBorder={false}
                hasData={Boolean(props.form.syn_prognostics)}
                onEdit={id => setShowEdit(id === showEdit ? null : id)}
                isLocked={isLocked}
                icon={getIconByName('prognostics')}
              />
            </Attw>
            {
              (showEdit === 'syn_prognostics') &&
              <EditForm
                type={'syn_prognostics'}
                onClose={() => closeEdit(showEdit)}
                form={props.form}
                onSubmit={props.onFormEdit}
                currentMode={props.currentMode}
              />
            }
          </EditFormWrapper>
        }


        {screenWidth && screenWidth < 1280
          ? Boolean(props.auxModes?.length)
            ? <Select
              isTop={true}
              hideSelected={true}
              selected={props.auxModes
                .map((m, i) => props.modes?.includes(m) ? i : -1)
                .filter(id => id >= 0)
              }
              options={props.auxModes.map((m, i) => ({
                id: i,
                Icon: getModeMetaData(m)?.icon,
                title: t(getModeMetaData(m)?.title ?? ''),
                action: (item) => { onModeChanged(m) },
                rightIcon: () => null,
              }))}
              onSelect={() => { }}
              hideKeyIcon
              closeOnClickOutside={true}
              closeOnSelect={true}
            />
            : null
          : <>
            {
              noCompatibilityAuxModes
                .map(m =>
                  <Attw
                    text={t(getModeMetaData(m, props.form)?.title ?? '')}
                    key={`tw_aux_${m}_${Date.now()}`}
                    align="left"
                  >
                    <InstrumentButton
                      key={`aux_${m}`}
                      id={m}
                      onClick={(ev) => {
                        let correctId = m;
                        if (m as string === 'synastry') { correctId = 'compatibility1' }
                        else if (m as string === 'prognostics') { correctId = 'directions' }
                        onModeChanged(correctId as CircleMode, ev.ctrlKey || ev.metaKey);
                      }}
                      isActive={props.modes.includes(m)}
                      onReplace={props.onReplace}
                      isHighlighted={props.currentMode === m}
                      isLocked={isLocked}
                      icon={getModeMetaData(m)?.icon}
                    />
                  </Attw>
                )
            }

            {
              Boolean(compatibilityAuxModes?.length)
                ? compatibilityAuxModes?.length === 1
                  ? compatibilityAuxModes
                    .map(m =>
                      <Attw
                        text={t(getModeMetaData(m)?.title ?? '')}
                        key={`_tw_aux_${m}`}
                        align="left"
                      >
                        <InstrumentButton
                          key={`aux_${m}`}
                          id={m}
                          onClick={(ev) => {
                            let correctId = m;
                            if (m as string === 'synastry') { correctId = 'compatibility1' }
                            else if (m as string === 'prognostics') { correctId = 'directions' }
                            onModeChanged(correctId as CircleMode, ev.ctrlKey || ev.metaKey);
                          }}
                          isActive={props.modes.includes(m)}
                          onReplace={props.onReplace}
                          isHighlighted={props.currentMode === m}
                          isLocked={isLocked}
                          icon={getModeMetaData(m)?.icon}
                        />
                      </Attw>
                    )
                  : <Select
                    isTop={true}
                    hideSelected={true}
                    selected={compatibilityAuxModes
                      .map((m, i) => props.modes?.includes(m) ? i : -1)
                      .filter(id => id >= 0)
                    }
                    options={compatibilityAuxModes
                      .map((m, i) => ({
                        id: i,
                        Icon: getModeMetaData(m)?.icon,
                        title: t(getModeMetaData(m)?.title ?? ''),
                        action: (item: any, evt: MouseEvent) => {
                          onModeChanged(m)
                        },
                        rightIcon: () => null,
                      }))
                    }
                    isActive={props.modes.some(isCompatibility)}
                    hideKeyIcon
                    closeOnClickOutside={true}
                    closeOnSelect={true}
                    icon={<CompatibilityIcon />}
                    showAsModeButton={true}
                  />
                : null
            }
          </>
        }

        {showIsLockedLeftPopup.value && <PopupNotify
          buttons={store.settings.user.auth.id === -1 ?
            [{
              title: "base.signIn",
              onClick: (evt: any) => {
                // evt.stopPropagation();
                clearURLSearchParams('share');
                setTimeout(() => api.goToAuth(), 16);
              },
              color: 'var(--accent-blue)'
            }] :
            []
          }
          title={t("chronos.app.instruments.footer.cannotEditTheMap")}
          text={store.settings.user.auth.id === -1 ?
            "chronos.app.instruments.footer.signToChanges" :
            "chronos.app.instruments.footer.saveToChanges"
          }
          align='left'
        />}
      </LeftGroup>

      {(!isLocked || props.form.access.showPersonalData) && (
        <CenterGroup ref={centerGroupRef}>
          {showTimeControlsSelector
            ? <Select
              isTop={true}
              hideSelected={true}
              selected={selectedModeNumber}
              options={modeSelectOptions}
              onSelect={(id: number) => setSelectedModeNumber(id)}
              hideKeyIcon
              closeOnClickOutside={true}
              closeOnSelect={true}
              icon={<BaseTimeControlIcon />}
              extraIcon={<ChevronsUpDownIcon />}
              showAsModeButton={true}
            />
            : null
          }
          {(showNatalTimeControl && !showSecondTimeControl || !secondTimeData) && baseTimeData &&
            <TimeControl
              type={'base'}
              data={baseTimeData}
              onChange={onDateTimeChanged}
              setShowTimeLine={setShowTimeLineBase}
              showTimeLine={showTimeLineBase}
              icon={showTimeControlsSelector ? null : BaseTimeControlIcon} // FIXME: natal, partner1, partner2
              isLocked={isLocked || props.limitedAccess}
              onNotify={showIsLockedPopup.on}
              useHotkeys
              autoPrediction={props.autoPrediction}
              form={props.form}
              onSubmit={props.onFormEdit}
              mode={baseTimeData.forMode}
              modes={props.modes}
              limitedAccessAction={props.limitedAccessAction}
            />}

          {secondTimeData && ((isTablet && showSecondTimeControl) || !isTablet && !(props.modes.some((item, i, arr) => item === 'syn_natal' && arr.length === 1))) && (
            <>
              <TimeControlSplitter />
              <TimeControl
                type="ext"
                data={secondTimeData}
                onChange={onDateTimeChanged}
                setShowTimeLine={setShowTimeLineExt}
                showTimeLine={showTimeLineExt}
                icon={SecondTimeControlIcon} // FIXME: partner2, prognostics (all modes as single)
                isLocked={isLocked}
                onNotify={showIsLockedPopup.on}
                useHotkeys
                autoPrediction={props.autoPrediction}
                form={props.form}
                onSubmit={props.onFormEdit}
                mode={secondTimeData.forMode}
                modes={props.modes}
              />
            </>
          )}

          {isTablet && (isPrognostics(props.modes) || props.modes.some(isCompatibility) || (props.modes.length === 2 && partnerModes.length === 2)) && (
            <>
              <TimeControlSplitter />
              <InstrumentButton
                id={showSecondTimeControl ? getSecondTimeId() : getBaseTimeId()}
                isActive
                activeStyle="color"
                onClick={() => setShowSecondTimeControl(!showSecondTimeControl)}
                isLocked={isLocked}
                icon={ChevronUpDownIcon}
              />
            </>
          )}

          {showIsLockedPopup.value &&
            <PopupNotify
              buttons={store.settings.user.auth.id === -1 ?
                [{
                  title: "base.signIn",
                  onClick: () => {
                    clearURLSearchParams('share');
                    setTimeout(() => api.goToAuth(), 16);
                  },
                  color: 'var(--accent-blue)'
                }] :
                []
              }
              title={t("chronos.app.instruments.footer.cannotEditTheMap")}
              text={store.settings.user.auth.id === -1 ?
                "chronos.app.instruments.footer.signToChanges" :
                "chronos.app.instruments.footer.saveToChanges"
              }
              align='center'
            />
          }


          {showRefreshMap && <RefreshIconWrapper onClick={onRefreshHandler}><RefreshIcon /></RefreshIconWrapper>}

          {showConfirmation.value &&
            <Confirmation
              title={t("base.resetFormations")}
              submitText={t("base.confirm")}
              onSubmit={onSubmitHandler}
              onCancel={showConfirmation.off}
            >
              <ConfirmationText>
                {t("base.resetFormationsInfo")}
              </ConfirmationText>
            </Confirmation>
          }
        </CenterGroup>
      )}

      <RightGroup>
        <WidgetsButtons limitedAccess={props.limitedAccess}
          limitedAccessAction={props.limitedAccessAction}
          widgets={[...workWidgets, ...paidBuildings]}
          isLocked={isLocked}
          modes={props.modes}
          onClick={(id) => props.showWidget(id)}
          onReplace={props.onReplace}
          />
        <SwitchWidgets onClick={props.showAllWidgets} />
      </RightGroup>
    </Container>
  );
});

const Container = styled.footer<{ noTime: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: ${props => props.noTime ? '1.75em' : '0.5em'};
  width: 100%;
  box-sizing: border-box;
  z-index: 7;

  ${isTablet
    ? css`
        position: fixed;
        left: 1rem;

        @media (max-width: 1366px) {
          width: calc(100% - 1rem);
        }

        @media (max-width: 959px) {
          justify-content: space-between;
        }
      `
    : css`
      position: absolute;
    `
  }
`;

const LeftGroup = styled.div`
  position: absolute;
  display: flex;
  left: 1rem;

  & > * {
    margin-right: 0.625rem;
  }

  & > :last-child {
    margin-right: 0;
  }

  ${isTablet
    ? css`
        @media (max-width: 959px) {
          position: static;
        }
      `
    : ''}
`;

const RightGroup = styled.div`
  position: absolute;
  display: flex;
  right: 0.5rem;

  ${isTablet
    ? css`
        @media (max-width: 959px) {
          position: static;
        }
      `
    : ''}
`;

const CenterGroup = styled.div`
  display: flex;
  align-items: center;

  ${isIPad ? css`margin-bottom: 1rem` : ''}
`;

const EditFormWrapper = styled.div`
  position: relative;
`;

const TimeControlSplitter = styled.div`
  width: 0.5rem;
`;

const SwitchWidgets = styled.div`
  height: 2.375rem;
  width: 0.4rem;

  border-radius: 0.25rem;

  cursor: pointer;

  background-color: var(--element-neutral);
`;

const RefreshIconWrapper = styled.div`
  margin-left: 0.5rem;
  margin-right: 5rem;
  color: var(--icon-secondary);
  cursor: pointer;

  svg {
    width: 1.125rem;
  }
`;

const ModesButton = styled.div<{ up: boolean }>`
  position: relative;
  display: flex;
  /* flex: 0.95 1 0%; */
  justify-content: center;
  align-items: center;
  width: 2.25rem;
  height: 2.25rem;
  padding: 0px;

  border: 1px solid var(--input-border);
  border-radius: 0.375rem;
  background-color: var(--element-neutral);

  font-size: 0.875rem;
  font-weight: 600;
  color: var(--text-third);

  cursor: pointer;

  ${props => props.up && css`
    transform: rotate(180deg);
  `}

  & svg {
    width: 1.5rem;
    height: 1.5rem;
    transition: transform 0.2s ease;
  }

  &.open-menu svg {
    transition: transform 0.2s ease;
    transform: rotate(180deg);
  }
`;

const ConfirmationText = styled.p`
  margin: 1em 0;
  color: var(--text-third);
  font-size: 0.875rem;
`;
